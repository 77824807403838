.button {
  :global(.old-svg-size) {
    display: flex;
  }

  span:global(.MuiButton-icon) div {
    display: flex;
    align-items: center;
  }
}

.noIconMargin {
  span[class*='MuiButton-startIcon'] {
    margin-right: 0;
  }
}
