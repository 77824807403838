@import 'src/theme/scss/vars.module';

.itemFilterContainer {
  padding: 0;
  margin-bottom: 20px;

  .title {
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    svg {
      color: $secondary;
    }

    &:hover {
      svg {
        color: $primary;
      }

      color: $primary;
    }

    &:active {
      svg {
        color: $primary;
      }

      color: $primary;
    }
  }

  .allVariantsButton {
    @media (min-width: $layoutBreakpointLg) {
      display: none;
    }
  }
}
