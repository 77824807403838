.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 132px;

  padding: 8px 12px 8px 16px;

  border-left-width: 5px;
  border-left-style: solid;
  border-radius: 4px;
  background-color: #fff;

  box-shadow: 0 1px 4px 1px rgba(40, 48, 60, 0.1),
    0 1px 2px -1px rgba(40, 48, 60, 0.1);

  cursor: default;

  @media (max-width: 400px) {
    width: 320px;
  }
}
