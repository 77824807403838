.sumSpecification {
  display: flex;
  align-items: center;

  gap: 6px;
  padding: 8px 10px;

  color: #16651a;
  background-color: #dcf4dd;
  border: 1px solid #79ca7d;
  border-radius: 8px;
}
