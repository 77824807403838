$primaryText: #292929;
$secondaryText: #7a8694;
$warningText: #b75200;

$primary: #0041a0;
$primaryDark: #002f6c;
$primaryLight: #f3f6fb;

$blueContentSecondary: #0041a0;
$darkBlueContentSecondary: #001e46;

$secondary: #7a8694;
$secondaryLight: #9fa8b3;
$secondaryDark: #575f69;

$info: #4e60c0;
$warning: #b47742;
$warningLight: #ffdcbf;
$warningDark: #ff7400;

$success: #13b81a;
$successLight: #dcf4dd;

// https://stackoverflow.com/a/15763085
$danger: #{'#FF0000'};
$dangerDark: #e24343;
$dangerLight: #ffe6e6;

$commonWhite: #fff;
$commonDark: #000;

$textGreen: #16651a;
$primaryBlue: #cde3ff;
$borderGrayPrimary: #dedede;

$overlayBlueDefault: #002f6c0f;
$overlayBlue4Active: rgba(0, 91, 209, 0.12);

:export {
  primaryText: $primaryText;
  secondaryText: $secondaryText;
  warningText: $warningText;
  info: $info;
  primary: $primary;
  primaryDark: $primaryDark;
  secondary: $secondary;
  secondaryLight: $secondaryLight;
  secondaryDark: $secondaryDark;
  success: $success;
  successLight: $successLight;
  warning: $warning;
  warningDark: $warningDark;
  danger: $danger;
  dangerDark: $dangerDark;
  dangerLight: $dangerLight;
  commonWhite: $commonWhite;
  commonDark: $commonDark;
  textGreen: $textGreen;
  primaryBlue: $primaryBlue;
  borderGrayPrimary: $borderGrayPrimary;
  overlayBlueDefault: $overlayBlueDefault;
  overlayBlue4Active: $overlayBlue4Active;
  primaryLight: $primaryLight;
  blueContentSecondary: $blueContentSecondary;
  darkBlueContentSecondary: $darkBlueContentSecondary;
}
