@import 'src/theme/scss/vars.module';

a {
  text-decoration: none;
  color: $primary;
}

.wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  &.loading:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(#ffffff, 0.5);
  }
}

.wrapperTable {
  overflow-x: auto;
}

.scroll {
  @extend %customScroll;
}

.thCell {
  font-size: 14px;
  color: $secondary;

  svg {
    transform: rotate(-90deg);

    path {
      stroke: $secondary;
    }
  }
}

.containerCounterSelected {
  padding: 4px 16px;
  background-color: rgba(0, 47, 108, 0.28);
  border-radius: 30px;
}

.firstBtn {
  transform: rotate(180deg);
}

.selectedRow {
  background-color: #cde3ff;
}

.arrow {
  transform: rotate(-90deg);
}
