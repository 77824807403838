@import 'src/theme/scss/vars.module';

.tabs {
  height: 44px;
  min-height: 0;
  padding: 4px;
  background: #fff;
  border-radius: 12px;

  :global(.MuiTabs-scrollButtons.Mui-disabled) {
    opacity: 1;
  }
}

.navItem {
  color: $primaryText;
  min-height: 0;
  height: 36px;
  padding: 0 12px;
  background-color: transparent;
  border-radius: 8px;

  &:hover {
    background-color: inherit;
    color: black;
  }

  &Active {
    background-color: #f3f6fb;
    color: $primaryText;
    font-weight: 500;

    &:hover {
      background-color: #f3f6fb;
    }
  }
}
