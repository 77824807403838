.wrapper {
  max-width: 196px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  padding: 8px 16px 16px;
  box-shadow: 0px 1px 3px 0px rgba(40, 48, 60, 0.06), 0px 2px 20px 0px rgba(40, 48, 60, 0.08), 0px 4px 8px 0px rgba(40, 48, 60, 0.04);
  border-radius: 6px;

  .btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .color {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    border: none;
    cursor: pointer;

    &.active {
      border: 2px solid rgba(0, 0, 0, 0.20);
    }
  }
}