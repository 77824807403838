.inputRow {
  display: flex;

  & > div {
    width: 144px;
    margin-right: 20px;
    margin-bottom: 20px;
    flex: none;
  }
}
