@import 'src/theme/scss/vars.module';
$tableRadius: 12px;

.firstBtn {
  transform: rotate(180deg);
}

.arrow {
  color: $primaryDark;
}

.nextArrow {
  color: $primaryDark;
  transform: rotate(180deg);
}

.pagination {
  background: $commonWhite;
  border-radius: $tableRadius;
  padding: 20px;
  justify-content: space-between;
}
