.container {
  min-width: 110px;

  &.editNameRequest {
    flex: 1 1 auto;
  }

  &View {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.formMargin {
  margin-bottom: 24px;
}

.input {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  padding: 0 4px;
  border: 1px solid #9fa8b3;
  border-radius: 4px;

  &:hover {
    border: 1px solid #9bc6ff;
  }

  & svg {
    margin-left: 10px;
  }
}
