@import 'src/theme/scss/vars.module';

.title {
  color: $primary;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.stack {
  *[class*='info-stack_content'] {
    align-items: center;
  }
}

.fileIcon {
  background-color: rgba(0, 47, 108, 0.04);
  width: 40px;
  height: 40px;
}
