.container {
  margin-top: -4px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 40px;
}

.containerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .orderId {
    width: 500px;
    height: 30px;
  }

  .containerBtn {
    display: flex;
    gap: 16px;

    & .btnOne {
      width: 150px;
      height: 30px;
    }

    & .btnTwo {
      width: 150px;
      height: 30px;
    }
  }
}

.containerTabs {
  margin-bottom: 16px;
  max-width: 100%;

  & .tabGroups {
    height: 30px;
  }
}
