@import 'src/theme/scss/vars.module';

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: calc(50% - 10px);
}

.help {
  margin-top: 4px;
  font-size: 12px;
}

.error {
  & .fileUploaderContent {
    border-color: $danger;
  }

  & .help {
    color: $danger;
  }
}

.sectionWrapper {
  max-height: 420px;
  overflow-y: auto;
  padding-right: 4px;

  @extend %customScroll;
}
