.container {
  max-width: 1920px;
  height: 50px;

  margin: -24px -60px;
  padding: 0 60px;

  position: sticky;
  z-index: 11;

  box-shadow: none;
  border: 1px solid transparent;
}

.isShowBackground {
  border: 1px solid #dedede;
  background-color: #ffffff;
}
