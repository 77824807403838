@import 'src/theme/scss/vars.module';

.toolbarGrid {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 1fr 1fr;
}

.styleButton {
  margin-right: 8px;
  font-size: 1rem;
  padding: 10px;
  border: none;
  cursor: pointer;
}
