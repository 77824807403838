@import 'src/theme/scss/_vars.module.scss';

.aside {
  width: 400px;
  background-color: $commonWhite;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: min-content;
  padding: 20px;
  border-radius: 12px;
  position: sticky;
  // TODO: заменить на константу
  top: 124px;

  transition: width 100ms;

  &Tablet {
    width: 300px;
    gap: 4px;

    .block {
      .blockContent {
        .menuItem {
          padding: 0;
        }
      }
    }
  }

  &Mobile {
    width: 100%;
    gap: 12px;

    .wrapper {
      padding: 0;
    }

    // .block {
    //   .blockContent {
    //     .menuItem {
    //       padding: 0;
    //     }
    //   }
    // }
  }

  &Collapsed {
    width: 72px;
    gap: 4px;
    align-items: center;

    .block {
      .blockContent {
        align-items: center;

        .menuItem {
          padding: 0;
        }
      }
    }
  }
}

.block {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .blockContent {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .menuItem {
      color: $commonDark;
      padding: 10px 12px;
      background-color: transparent !important;

      &:global(.active) {
        background-color: $primaryLight !important;
        color: $blueContentSecondary;
        border-radius: 12px;
      }

      &.activeMenuItem {
        background-color: $primaryLight !important;
        color: $blueContentSecondary;
        border-radius: 12px;
      }
    }
  }
}

.toggleButton {
  cursor: pointer;

  &Collapsed {
    transform: rotate(180deg);
  }
}

.mobileWrapper {
  // padding: 0 20px;
  padding: 0;

  .arrow {
    transform: rotate(-90deg);
    margin-right: 4px;
  }
}
