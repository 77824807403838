@import 'src/theme/scss/vars.module';

.tag {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  padding: 2px 5px;

  border: none;
  border-radius: 4px;
  background: #002f6c0f;
  color: #292929;
}
