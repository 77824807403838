.tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 20px;
  gap: 4px;
  min-width: 150px;

  background-color: var(--cometal-panel-background-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: var(--cometal-border-radius);
}
