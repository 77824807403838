.arrow {
  top: unset
}

.select {
  background-color: #FFF;

  &:focus {
    background-color: #FFF;
  }
}