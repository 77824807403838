@import 'src/theme/scss/vars.module';

.card {
  padding: 16px 20px;

  &Disabled {
    * {
      color: #838383;
    }

    .contact {
      color: #838383;
    }

    svg {
      path {
        stroke: #838383;
      }
    }
  }

  &SMALL_GRAY {
    background-color: #f3f6fb;
    box-shadow: none;
    padding: 8px 8px 8px 12px;
    border-radius: 8px;
  }
}

.actionButton {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.05);

  path {
    stroke: #292929;
  }
}

.icon {
  width: 18px;
  height: 18px;
}

.emailContainer {
  min-width: 0;
}

.contact {
  color: #000000;
  font-size: 12px;
}

// TODO Denis
