.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &Group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 14px;

    @media (max-width: 1300px) {
      flex-wrap: wrap;
      gap: 0;
    }
  }

  &One {
    width: 100%;
    max-width: 630px;

    display: flex;
    flex-direction: column;
    gap: 24px;
    //height: fit-content;
  }

  &Two {
    width: 100%;
    max-width: 630px;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.whiteIcon {
  path {
    stroke: white;
  }
}
