.actionButton {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.05);

  &Disabled {
    svg {
      path {
        stroke: #9fa8b3 !important;
      }
    }
  }
}

.managerDisabled {
  .managerTitle {
    * {
      color: #bcbcbc !important;
    }
  }
}
