@import 'src/theme/scss/vars.module';

$sizeBetweenCards: 20px;
$requestsFiltersHeight: 72px;
$columnsHeaderHeight: 8px;

%indentationBetweenCards {
  gap: $sizeBetweenCards;
}

%sizeCard {
  min-width: 344px;
  max-width: 344px;

  @media (max-width: 400px) {
    min-width: 320px;
    width: 320px;
  }
}

.container {
  max-height: calc(
    100vh - #{$headerHeight} - #{$requestsFiltersHeight} - #{$columnsHeaderHeight}
  );
  cursor: grab;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  flex: 1;

  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    background: transparent !important;
    -webkit-appearance: none !important;
  }

  // Разворот для того, чтобы скролл показывался сверху https://stackoverflow.com/a/63507769
  &.rotate {
    transform: rotateX(180deg);
  }
}

.header {
  height: 100%;
  display: flex;
  @extend %indentationBetweenCards;

  padding-left: 60px;

  background-color: #f0f2f5;
  position: sticky;
  top: 0;
  z-index: 100;

  &:after {
    content: '';
    display: block;
    padding-right: 40px;
  }
}

.headerCard {
  @extend %sizeCard;

  padding: 20px 16px 12px;

  font-size: 12px;
  font-weight: 400;
  border-bottom: 4px solid #ffdcbf;

  position: relative;

  &NoBorder {
    border-bottom: none;
  }

  @media (max-width: 400px) {
    min-width: 320px;
    width: 320px;
  }

  &:after {
    content: '';
    display: block;
    background-color: #f0f2f5;
    width: $sizeBetweenCards;
    height: 100%;
    position: absolute;
    top: 0;
    right: -$sizeBetweenCards;
  }

  &Title {
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.33;
  }

  &.borderBottomGreen {
    border-bottom: 4px solid #79ca7d;
    background-color: #f0f2f5;
  }
}

.noWrapTitle {
  white-space: nowrap;
}

.titleCard {
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.33;
}

.cardList {
  display: flex;
  @extend %indentationBetweenCards;
  padding-left: 60px;
  padding-bottom: 8px;

  &Column {
    @extend %sizeCard;

    display: flex;
    flex-direction: column;

    gap: 8px;
    margin: 0;
    padding: 0;
  }

  & .card {
    &.borderLeftGreen {
    }
  }

  & .cardAssigned {
    background-color: #d4dfee;
  }

  & .card:first-child {
    margin-top: 8px;
  }
}

// Разворот обратно для того, чтобы скролл показывался сверху https://stackoverflow.com/a/63507769
.rotateBack {
  transform: rotateX(180deg);
}
