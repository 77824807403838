.containerBidNumber {
  display: flex;
  align-items: center;
  border-radius: 9999px;
  padding: 4px 8px;
  height: 24px;

  & .bidNumberText {
    display: flex;
    gap: 5px;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
  }

  & .bidNumberBtn {
    display: flex;
    align-items: center;
    gap: 13px;
    margin-left: 4px;

    & a {
      display: flex;
    }
  }
}
