@import 'src/theme/scss/vars.module';

.container {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 6px 16px;
  border-radius: 16px;

  svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }

  svg path {
    stroke: $primaryText;
  }
}

.textWrapper {
  &ERROR {
    @extend .container;

    background-color: $dangerLight;
  }

  &INFO {
    @extend .container;

    background-color: #cde3ff;
  }

  &SUCCESS {
    @extend .container;

    background-color: $successLight;
  }

  &WARNING {
    @extend .container;

    background-color: #fcecb2;
  }
}

.textWrapperBorder {
  &ERROR {
    border: 1px solid #ff9393;
  }

  &INFO {
    border: 1px solid #9bc6ff;
  }

  &SUCCESS {
    border: 1px solid #79ca7d;
  }

  &WARNING {
    border: 1px solid #fcecb2;
  }
}

.textWrapperText {
  &ERROR {
    color: $dangerDark;
    svg path {
      stroke: $dangerDark;
    }
  }

  &INFO {
    color: $primary;

    svg path {
      stroke: $primary;
    }
  }

  &SUCCESS {
    color: $success;

    svg path {
      stroke: $success;
    }
  }

  &WARNING {
    color: #292929;

    svg path {
      stroke: #292929;
    }
  }
}
