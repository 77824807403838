.adornedEnd {
  padding-right: 0;
}

.inputAdornedEnd {
  padding-right: 48px;
}

.inputAdornedEndWrapper {
  position: absolute;
  right: 14px;
}