.statusWrapperBase {
  width: fit-content;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  border-radius: 8px;
  gap: 4px;
}

.chipBlue {
  @extend .statusWrapperBase;

  background-color: #cde3ff;

  svg path {
    stroke: #005bd1;
  }
}
