@import 'src/theme/scss/vars.module';
$tableRadius: 12px;

.cell {
  border: none !important;
  background: $commonWhite;
  padding: 16px 20px;
  font-size: 14px;

  &:first-child {
    border-top-left-radius: $tableRadius;
    border-bottom-left-radius: $tableRadius;
  }

  &:last-child {
    border-top-right-radius: $tableRadius;
    border-bottom-right-radius: $tableRadius;
  }
}
