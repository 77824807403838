@import 'src/theme/scss/vars.module';

.menuItemWrapper {
  height: 44px;
  padding: 0 16px;
  color: $secondaryText;
  border-radius: 30px;

  .label {
    display: none;
  }

  &:global(.active) {
    background-color: $primary;
    color: $commonWhite;

    .label {
      display: block;
    }
  }
}
