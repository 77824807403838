@import 'src/theme/scss/vars.module';

$gap: 24px;
$widthLeftColumn: 432px;
$smallWidthLeftColumn: 312px;
$widthRightColumn: 432px;

.container {
  display: flex;
  gap: $gap;
  width: calc(100% - #{$widthRightColumn} - #{$gap});

  @media (max-width: $layoutBreakpointXxl) {
    width: 100%;
  }
}

.containerCustomerTabs {
  & .tabs {
    width: $widthLeftColumn;
    height: 112px;
    margin-bottom: 15px;

    @media (max-width: 1725px) {
      width: $smallWidthLeftColumn;
      flex: 0 0 $smallWidthLeftColumn;
    }
  }

  & .btn {
    width: 164px;
    height: 48px;
  }
}

.containerContent {
  max-width: calc(100% - #{$widthLeftColumn} - #{$gap});
  flex: 0 0 calc(100% - #{$widthLeftColumn});

  @media (max-width: 1725px) {
    max-width: calc(100% - #{$smallWidthLeftColumn} - #{$gap});
    flex: 0 0 calc(100% - #{$smallWidthLeftColumn});
  }

  & .title {
    height: 64px;
    margin-bottom: 15px;
  }

  & .contentAbout {
    height: 164px;
  }
}
