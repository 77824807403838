.card {
  padding: 16px 20px 20px 20px;
}

.iconFullWidth {
  width: 100%;
  padding: 16px 0;
  background-color: rgba(0, 47, 108, 0.06);
  color: #292929;

  &:hover {
    background-color: rgba(0, 47, 108, 0.06);
  }
}
