@import 'src/theme/scss/vars.module';

.card {
  padding: 8px 12px;
  height: 60px;
  border-radius: 8px;
  background-color: #f3f6fb;

  &:hover {
    background-color: #e3e8ef;
  }

  &Open {
    height: fit-content;
  }

  > * {
    min-width: 0;
  }
}

.avatar {
  flex-grow: 0;
  flex-shrink: 0;
}

.userInfo {
  flex-grow: 1;
  flex-shrink: 1;
}

.actionsBar {
  flex-grow: 0;
  flex-shrink: 0;
}

.popper {
  color: rgb(41, 41, 41);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(40, 48, 60, 0.04) 0 4px 8px,
    rgba(40, 48, 60, 0.08) 0 2px 20px, rgba(40, 48, 60, 0.06) 0 1px 3px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 17px 28px 19px;
  border-bottom: 1px solid #dedede;
}

.content {
  padding: 24px 28px;
  background: #fff;
  border-radius: 12px 12px 0 0;
}

.avatarWrapper {
  position: relative;

  .avatarBadge {
    position: absolute;
    top: -6px;
    left: 24px;
  }

  .avatarIcon {
    color: #0041a0;

    &Filled {
      color: #fff;
    }
  }
}
