.wrapper {
  display: flex;
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #dedede;
  color: #9fa8b3;
  font-size: 14px;
}
