@import 'src/theme/scss/vars.module';

.itemFilterContainer {
  flex: 0 0 #{'max(25%, 200px)'};
  width: #{'max(25%, 200px)'};
  padding: 0 15px;
  margin-bottom: 45px;
  h4 {
    min-height: 40px;
  }

  @media (max-width: $layoutBreakpointLg) {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    margin-bottom: 25px;
  }
}

.company {
  flex: 0 0 #{'max(30%, 300px)'};
  width: #{'max(30%, 300px)'};
}
