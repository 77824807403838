@import 'src/theme/scss/vars.module';

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.help {
  margin-top: 4px;
  font-size: 12px;
}

.error {
  & .fileUploaderContent {
    border-color: $danger;
  }

  & .help {
    color: $danger;
  }
}
