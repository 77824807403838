.contentWrapper {
  display: flex;
  gap: 24px;
  max-width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  min-width: 200px;
}

.inputBlock {
  display: flex;
  justify-content: space-between;
}

.contentBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 0;
  justify-content: space-between;

  & > div {
    margin-right: 20px;
  }
}

.block {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
}
