.wrapper {
  padding: 5% 25% 0;

  &.noContentPadding {
    padding: 0;
  }
}

.card {
  text-align: center;
  word-break: break-word;
  &Icon {
    margin-bottom: 16px;
  }
  &Content {
    width: 40%;
  }
}
