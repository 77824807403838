@import 'src/theme/scss/vars.module';

.fileList {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .sectionTitle {
    margin-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
  }

  .fileColumn {
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 1rem;
    height: calc(100vh - 200px);
    overflow-y: auto;
  }

  .sectionWrapper {
    max-height: 430px;
    overflow-y: auto;
    padding-right: 4px;

    @extend %customScroll;
  }
}
