.chip {
  margin-bottom: 8px;
  background: rgba(0, 47, 108, 0.06);
  border: 1px solid #dedede;

  height: auto;
  min-height: 32px;

  & span {
    white-space: pre-line;
    overflow-wrap: break-word;
  }

  &:hover {
    background: rgba(0, 47, 108, 0.06);
    cursor: pointer;
  }
  &Active {
    border: 1px solid #0041a0;
  }
}

.accordion {
  width: 100% !important; // По другому никак не перебивается
  box-shadow: none;
}

.labelWrapper {
  display: flex;
}

.labelTags {
  margin-left: 8px;
  color: #7a8694;
}

.accordionBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 26px;
  padding: 10px 0;
}

.accordionSummary {
  padding: unset;
}
