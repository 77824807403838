.file {
  flex: 1;
  display: flex;
  flex-direction: column;

  &Data {
    flex: 1;
    display: flex;
    align-items: center;
    border: 1px solid #E7E7E7;
    border-radius: 8px;
    padding: 6px;
    margin-top: 10px;

    &Icon {
      flex: 0 0 auto;
      height: 40px;
    }

    &Info {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      min-width: 0;
      margin-left: 10px;
    }

    &Actions {
      flex: 0 0 auto;

      button:first-child {
        margin-left: 16px;
      }

      button:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &UploadData {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;

    &Author {
      margin-left: 8px;
    }
  }
}

.disabled {
  background: rgba(0, 47, 108, 0.04);
}

.borderNone {
  border: none;
}