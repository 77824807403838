.container {
  width: 100%;
  max-width: 1200px;

  &Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 36px 8px;
  }

  &Content {
    padding: 0px 36px;
    margin: 24px 0;
  }

  &Actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 36px 24px;

    border-top: 1px solid #dedede;
  }
}

.list {
  display: flex;
  flex-direction: row;

  padding-bottom: 60px;
  gap: 72px;

  &Column {
    min-width: 327px;
    max-width: 327px;

    display: flex;
    flex-direction: column;

    gap: 8px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
