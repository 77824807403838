.wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #dedede;
  cursor: pointer;

  &NoBottomBorder {
    border-bottom: none;
  }
}

.content {
  padding: 16px 20px 20px 20px;

  &NoPadding {
    padding: 0;
  }
}
