.activityNameLine {
  width: 100%;
  max-width: 280px;

  display: flex;
  gap: 4px;
  padding: 3px 0;
}

.containerEllipsis {
  display: flex;

  & .ellipsis {
    position: relative;
    left: 5px;
    color: #7a8694;
  }
}

.activityNameFull {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.activityName {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  padding: 2px 5px;

  border: 1px solid #dedede;
  border-radius: 4px;

  color: #7a8694;
}
