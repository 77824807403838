.wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 1px rgba(40, 48, 60, 0.1),
    0 1px 2px -1px rgba(40, 48, 60, 0.1);
  border-radius: 12px;
  margin-bottom: 24px;

  &NoMargin {
    margin-bottom: 0;
  }

  &NoShadow {
    box-shadow: none;
  }
}

.expandMoreButtonRotated {
  transform: rotate(180deg);
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 17px 28px 19px;
  //border-bottom: 1px solid #dedede;
}

.content {
  padding: 24px 28px 28px;

  &NoPadding {
    padding: 0;
  }

  &NoOverflow {
    overflow: hidden;
  }
}
