@import 'src/theme/scss/_vars.module.scss';

.container {
  width: calc(100vw + 30px);
  // max-width: 1920px;
  min-height: 60px;
  display: flex;
  align-items: center;

  margin: 0 -60px;
  padding: 10px 60px;

  position: fixed;
  z-index: 11;

  box-shadow: none;
  border: 1px solid transparent;
  transition: box-shadow 0.25s ease-in-out;

  @media (max-width: $layoutBreakpointLg) {
    top: 0 !important;
  }
}

.isShowBackground {
  border: 1px solid #dedede;
  background-color: #ffffff;
  box-shadow: 0 3px 8px rgba(40, 48, 60, 0.04),
    0 2px 12px rgba(40, 48, 60, 0.08), 0 1px 3px rgba(40, 48, 60, 0.06);
}

.content {
}
