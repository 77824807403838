@import 'src/theme/scss/vars.module';

.headerOuterWrapper {
  left: 0;
  padding: 14px 40px;
  margin-left: 0;
  width: 100vw;
  border: none;
  min-height: 52px;

  @media (max-width: $layoutBreakpointXxl) {
    padding: 14px 24px;
  }

  @media (max-width: $layoutBreakpointSm) {
    padding: 14px 20px;
  }
}

.headerWrapper {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  max-width: 100%;

  & .header {
    overflow: hidden;
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    flex-grow: 1;

    .requestInfo {
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: 100%;
      flex-grow: 1;
    }

    .requestActions {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.container {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  flex-shrink: 0;

  &RequestInfo {
    display: flex;
    gap: 12px;

    flex: 1 1 auto;
    min-width: 0;
  }

  &Buttons {
    display: flex;
    gap: 24px;

    // & button {
    //   padding: 13px 16px 15px 16px;
    //   border-radius: 8px;
    //   gap: 11px;
    // }
  }
}
