@import 'src/theme/scss/vars.module';

.whiteInput {
  border: none;
  background-color: #fff;
  padding-right: 14px !important;

  & > * {
    border: none;
  }
}

.grayInput {
  border: none;
  background-color: $primaryLight;
  padding-right: 14px !important;

  & > * {
    border: none;
  }
}

.disable {
  background-color: transparent !important;
}

.clearIcon {
  svg path {
    stroke: $primary;
  }
}
