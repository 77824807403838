:root {
    --cometal-border-radius: 8px;
    --cometal-panel-background-color: #fff;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

@supports(min-height: 100dvh) {
  .app {
    min-height: 100dvh;
  }
}
