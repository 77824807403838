@import 'src/theme/scss/vars.module';

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  flex: 1;
}

.stickyButtonsContainer {
  display: flex;
  gap: 8px;
  align-content: center;
  flex-direction: row;
  background-color: #ffffff;
  width: 100%;
}
