@import 'src/theme/scss/vars.module';

.table {
  border-collapse: separate;
  border-spacing: 0 4px;
}

.wrapper {
  position: relative;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  &.loading:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(#ffffff, 0.5);
  }
}

.wrapperTable {
  overflow-x: auto;
}
