@import 'src/theme/scss/vars.module';

.wrapper {
  padding: 12px;
  flex-shrink: 0;
  box-shadow: none;

  @media (max-width: $layoutBreakpointLg) {
    padding: 16px 20px;
  }
}

.foreground {
  position: relative;
  z-index: 2;
}

.background {
  position: absolute;
  z-index: 1;
  right: 0;

  svg {
    color: $primaryBlue;

    circle {
      stroke-dashoffset: 0 !important;
    }
  }
}

.actionButton {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.statusButton {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 24px;

  :hover {
    background-color: $primaryBlue;
    border-radius: 4px;
  }
}

.managerCard {
}
