.wrapper {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  flex-direction: column;
}

.highlightedText {
  padding: 4px 8px;
  border: 1px solid #dedede;
  border-radius: 4px;
  background: rgba(0, 47, 108, 0.06);
}
