@import 'src/theme/scss/vars.module';
$tableRadius: 12px;

.thCell {
  border: none !important;
  background: $commonWhite;
  padding: 16px 20px;

  button {
    padding: 0;
    color: $commonDark;
    font-weight: 500;
  }

  svg {
    transform: rotate(270deg);
    height: 12px;
    width: 12px;
  }

  .thCellContent {
    font-size: 14px;
    color: $commonDark;
  }

  &:first-child {
    border-top-left-radius: $tableRadius;
    border-bottom-left-radius: $tableRadius;
  }

  &:last-child {
    border-top-right-radius: $tableRadius;
    border-bottom-right-radius: $tableRadius;
  }
}
