@import 'src/theme/scss/vars.module';

.wrapper {
  width: 94px;
  height: 37px;
  margin: 10px auto;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: rgba(54, 141, 255, 0.75);
  z-index: 1;
  position: sticky;
  top: 0;
  color: $commonWhite;
}
