.card {
  &Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 16px;
    text-align: center;
  }

  &Main {
    padding: 16px 20px 24px;
    &Item {
      margin-bottom: 16px;
    }
    &Button {
      margin-top: 16px;
      padding: 10px 16px;
    }
  }
}

.emptyText {
  padding: 0 0 0 16px;
}
