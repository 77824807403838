.wrapper {
  border-radius: 8px;
  padding: 15px 15px 17px;
  border: 1px solid #cacbcc;

  &.disabled {
    background: rgba(0, 47, 108, 0.04);
  }

  .img_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    object-fit: contain;
  }

  .info {
  }
}
