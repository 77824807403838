.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 19px;
  margin-bottom: 50px;

  &NoMargin {
    margin-bottom: 0;
  }
}
