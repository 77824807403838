@import 'src/theme/scss/vars.module';

.containerName {
  display: flex;
  align-items: center;
}

.name {
  color: $primary;
  cursor: pointer;
  margin-right: 10px;
  padding-left: 33px;
  font-size: 16px;
}
