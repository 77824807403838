@import 'src/theme/scss/vars.module';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.deleteIcon {
  display: flex;
  justify-content: flex-end;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex: auto;

  font-size: 16px;
  padding: 20px;

  border-radius: 12px;
  background-color: #ffffff;
  border-left: 4px solid transparent;

  &.active {
    border-left: 4px solid #368dff;
  }

  & .actionBtn {
    position: absolute;
    right: 8px;
    top: 15px;
    width: 30px;
  }
}

.crmLink {
  width: fit-content;

  display: flex;
  align-items: center;

  & svg {
    margin-left: 8px;
  }
}

.main {
  &Checkbox {
    &EmailWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      span {
        font-size: 16px;
      }

      button {
        margin-left: 12px;
      }
    }

    &Wrapper {
      display: flex;
      align-items: center;

      margin-bottom: 24px;
      padding: 18px 0;

      border-top: 1px solid #dedede;
      border-bottom: 1px solid #dedede;

      span {
        font-size: 16px;
      }

      label span {
        padding: 0 7px 0 0;
      }
    }
  }

  &Button {
    margin-bottom: 16px;
  }
}

.bottomMessage {
  min-height: 70px;
}

.closeBtn {
  width: 30px;

  position: absolute;
  right: 5px;
  top: 15px;
}

.icon {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.LOCKED {
    background-color: $dangerLight;

    svg {
      width: 9px;
    }
  }
}

.zeroMinWidthContainer {
  min-width: 0;
}

.whois {
  min-width: max-content;
}

.whiteSpace {
  white-space: normal;
}

.linkWrapper {
  width: 100%;
}
