@import 'src/theme/scss/vars.module';

.menuBlock {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 8px 16px;
  background-color: #ffffff;
  margin-bottom: 8px;
  border-radius: 12px;

  .menuItem {
    -webkit-tap-highlight-color: transparent;
    padding: 12px 0;
    width: 82px;
  }

  .iconWrapper {
    width: 40px;
    height: 40px;
    background-color: #f3f6fb;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
