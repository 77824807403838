.toggle {
  width: 28px;
  height: 28px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f6fb;

  &Active {
    background-color: #d6dee7;
    cursor: default;
  }

  &Left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &Right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
