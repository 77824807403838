@import 'src/theme/scss/vars.module';

.paper {
  width: 100%;
  background: $primaryLight;
}

.header {
  padding: 16px 20px;

  .titleWrapper {
    align-items: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  overflow: auto;
}

.mobileCloseButton {
  display: flex;
}
