@import 'src/theme/scss/vars.module';

.button {
  display: none;
  background-color: rgba($primaryDark, 0.06);
  &:hover,
  &:focus,
  &:active {
    background-color: rgba($primaryDark, 0.1);
  }
  & path {
    stroke: $secondary;
  }
  &.lg {
    @media (max-width: $layoutBreakpointLg) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.xl {
    @media (max-width: $layoutBreakpointXl) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
