@import 'src/theme/scss/vars.module';

.container {
  height: fit-content;

  position: relative;
  overflow: hidden;

  box-shadow: none;
  margin: 0;
  border-radius: 0;
}

.list {
  min-height: 200px;
  margin-top: 0;
  padding-left: 14px;

  display: flex;
  flex-direction: column;

  overflow-x: auto;
  list-style-type: none;

  @extend %customScroll;

  &.isHiddenStepper {
    max-height: 300px;
  }
}

.listItem {
  $sizeCircleInner: 8px;
  $sizeCircleOuter: 24px;
  $circleZIndex: 2;
  $lineZIndex: 1;

  display: flex;
  flex-direction: column;
  position: relative;
  padding: 12px 0 12px 33px;

  &.firstElement {
    .textWrapper:before {
      content: '';
      position: absolute;
      top: 20px;
      bottom: 0;
      left: -1px;
      border-left: 1px dashed #9fa8b3;
      z-index: $lineZIndex;
    }
  }

  &.lastElement {
    .textWrapper:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 20px;
      left: -1px;
      border-left: 1px dashed #9fa8b3;
      z-index: $lineZIndex;
    }
  }

  .textWrapper {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1px;
      border-left: 1px dashed #9fa8b3;
      z-index: $lineZIndex;
    }
  }

  &.completed {
    min-height: auto;
  }

  &:after {
    content: '';
    width: $sizeCircleInner;
    height: $sizeCircleInner;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    border-radius: 50%;

    position: absolute;
    top: 28px;
    left: -5px;

    z-index: $circleZIndex;
  }

  &:before {
    content: '';
    width: $sizeCircleOuter;
    height: $sizeCircleOuter;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #0041a0;
    border-radius: 50%;

    position: absolute;
    top: 20px;
    left: -13px;

    color: #fff;

    z-index: $circleZIndex;
  }

  &.notCompleted {
    &:before {
      content: '';
      width: $sizeCircleOuter;
      height: $sizeCircleOuter;

      background-color: #fff;
      border-radius: 50%;
      border: 1px solid #002f6c;

      position: absolute;
      top: 18px;
      left: -13px;

      z-index: $circleZIndex;
    }
  }
}

.label {
  max-width: 290px;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.containerActions {
  display: flex;
  justify-content: center;

  margin-top: 4px;
  padding-top: 15px;

  border-top: 1px solid #dedede;
}

.noOverflow {
  overflow: unset;
  max-height: none;
}
