@import 'src/theme/scss/vars.module';

.wrapper {
  display: flex;
  flex-grow: 9;
  width: 100%;

  > div {
    width: 100%;
    max-width: 620px;
  }
}

.inputSearch {
  width: 100%;
  max-width: 620px;
  height: 44px;
  margin-right: auto;
  background-color: #f0f2f5;
  border-radius: 8px;

  & > * {
    border: none;
  }

  & input {
    padding-left: 20px;
  }
}
