@mixin scrollbar-main($c: #e5e5e5, $w: 6px) {
  &::-webkit-scrollbar {
    width: $w;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $c;
    border-radius: 4px;
    width: 15px;
  }
}

@mixin line-clamp($numLines: 2, $lineHeight: 1.5) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block; // Fallback for non-webkit browsers
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  max-height: $numLines * $lineHeight + unquote('em');
}
