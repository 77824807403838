@import 'src/theme/scss/vars.module';

@mixin circleIcon {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  flex-shrink: 0;
}

.cardWrapper {
  border: 1px solid $primary;
  overflow-y: auto;

  @media (min-height: 300px) {
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #dedede;
    }
  }
}

.summaryWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paddingWrapped {
  padding: 16px 0;
}

.notificationList {
  padding: 0;

  :last-child {
    border-radius: 8px;
  }
}

.cardDate {
  color: $secondary;
  white-space: nowrap;
}

@media (max-width: 1210px) {
  .cardDate {
    white-space: normal;
  }
}

@media (min-width: 1501px) and (max-width: 1670px) {
  .cardDate {
    white-space: normal;
  }
}

.cardTitle {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 28px;
  justify-content: space-between;
  width: 100%;
}

.backgroundGray {
  background-color: #f4f4f4;
}

.mainData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.notificationWrapper {
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
  display: flex;
  gap: 16px;

  &.hover {
    cursor: pointer;
    transition: 0.2s ease-out;

    &:hover {
      background-color: #f4f4f4;
    }
  }
}

.dateWrapper {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
}

.notificationContent {
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 0;
}

.titleWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
}

.headerIcon {
  max-width: 65%;
  display: flex;
  gap: 16px;
  align-items: center;
}

.title {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unreadCircle {
  @include circleIcon;
  background-color: $primary;
}

.readCircle {
  @include circleIcon;
  background-color: #f3f6fb;
}

.iconWrapper {
  cursor: pointer;
  height: 100%;
}

.rightPaddingZero {
  padding-right: 0;
}

.iconExpanded {
  transform: rotate(180deg);
}

.fontWeight500 {
  font-weight: 500;
}

.backgroundBlue {
  background-color: #cde3ff;
}

.hidden {
  visibility: hidden;
}

.notificationBody {
  height: 40px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
}

.notificationBodyTooltip {
  font-size: 14px;
  height: 100%;
  white-space: normal;
  max-width: 100%;
}
