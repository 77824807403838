.variantContainer {
  height: 150px;
  overflow-y: auto;
  display: block;
}

.itemVariant {
  margin-bottom: 3px;
}

.searchForm {
  & > div {
    margin-bottom: 20px;
  }
}

