@import 'src/theme/scss/vars.module';

.scroll {
  @extend %customScroll;
}

.table {
  overflow: visible;

  [class^='table_wrapperTable'] {
    overflow: visible;
  }

  th {
    span {
      font-weight: 500;
    }
  }

  thead {
    position: sticky !important;
    top: 0;
  }

  .avatar {
    width: 40px;
    height: 40px;
    background-color: #f3f6fb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #292929;
    font-weight: 500;
    font-size: 13px;
    flex-shrink: 0;
  }
}

.phoneNumber {
  a {
    display: flex;
    height: min-content;
  }

  span {
    color: $secondaryText;
    font-size: 11px;
    line-height: 14px;
  }
}
