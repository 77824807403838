@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  display: inline-flex;
  animation: loadingCircle 1s infinite linear;
}
