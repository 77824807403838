.avatar {
  width: 40px;
  height: 40px;
  background-color: #f3f6fb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #292929;
  font-weight: 500;
  font-size: 12px;
  flex-shrink: 0;
}
