@import 'src/theme/scss/vars.module';

.container {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.containerTabs {
  list-style-type: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;

  & .tab {
    padding: 4px 0;
    display: inline-block;
    white-space: nowrap;
    border-bottom: 3px solid transparent;
    color: $primaryText;
    cursor: pointer;

    &[class~='active'] {
      font-weight: 500;
      border-color: #f00;
    }
  }
}
