@import 'src/theme/scss/vars.module';

.chip {
  height: 36px;
  padding: 0 12px;
  border-radius: 999px;
  background: $primaryLight;
  color: $primaryText;
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.activeChip {
  background: $primary;
  color: #fff;
}
