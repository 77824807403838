@import 'src/theme/scss/vars.module';

.icon {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &.ARCHIVED {
    background-color: $dangerLight;
    svg {
      width: 9px;
    }
  }
  &.INACTIVE {
    svg {
      width: 17px;
      height: 17px;
      path {
        stroke: $secondary;
      }
    }
  }
}
