@import 'src/theme/scss/vars.module';

.wrapper {
  height: 264px;

  .list {
    margin: 16px 0;
    height: 100%;
    overflow-y: auto;

    .item {
      padding-right: 10px;
      padding-bottom: 16px;
    }
  }
}
