.container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    min-width: 200px;
}

.priceWrapper {
    height: 30px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 8px;
    border-radius: 8px;
    background: #DCF4DD;
    color: #16651A;
    border: 1px solid #79CA7D;
}