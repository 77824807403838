@import 'src/theme/scss/vars.module';

.wrapper {
  width: 432px;
  height: 104px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 16px 15px;

  .mainPart {
    display: flex;
    justify-content: space-around;

    .textValue {
      color: #292929 !important;
      padding-top: 2px;
      width: 270px;
      font-weight: 500;
      height: 40px;
      @include line-clamp;
    }
  }

  .footerPart {
    padding-top: 5px;
    padding-left: 80px;
    display: flex;
    justify-content: space-between;
  }
}

.moreButton {
  font-size: 12px;
  padding-right: 10px;
  padding-left: 8px;
}

.iconWrapper {
  display: flex;
  align-items: flex-start;
}
