.swiper {
  position: relative;
}

.swiperPaginationOutside {
}

.prevSliderButton {
  position: absolute;
  left: 0;
  height: 24px;
  width: 24px;
  margin-left: 18px;
  z-index: 100;

  path {
    color: #fff;
  }

  svg {
    width: 8px;
    height: 14px;
  }
}

.nextSliderButton {
  position: absolute;
  right: 0;
  height: 24px;
  width: 24px;
  margin-top: 1px;
  transform: rotate(180deg);
  margin-right: 18px;
  z-index: 100;

  path {
    color: #fff;
  }

  svg {
    width: 8px;
    height: 14px;
  }
}
