@import '../../../theme/scss/vars.module';
.wrapper {
  border-bottom: 1px solid #dedede;
  padding: 16px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: 0px;
  }
}

.mainContact {
  &:before {
    content: 'Основной контакт';
    display: inline-block;
    background-color: #cde3ff;
    color: $primary;
    font-size: 14px;
    margin-bottom: 8px;
    padding: 5px 15px;
    border: 1px solid #9bc6ff;
    border-radius: 8px;
  }
}

.nameContact {
  white-space: break-spaces;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
}

.nameWrapper {
  display: flex;
  gap: 5px;
}
