@import 'src/theme/scss/vars.module';

.wrapper {
  position: relative;
  padding: 12px 16px;
  background-color: #f3f6fb;
  border: 1px dashed #dedede;
  border-radius: 8px;

  .text {
    @include line-clamp;

    white-space: unset;
    max-height: 40px;
  }
}

.button {
  position: absolute;
  top: 4px;
  right: 4px;

  path {
    stroke: #7a8694;
  }
}
