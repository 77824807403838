@import 'src/theme/scss/vars.module';

.fileUploader {
  flex: 1;
  display: flex;
  border-radius: 16px;

  &Content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    background: rgba(0, 47, 108, 0.06);
    border: 1px dashed #cacbcc;
    border-radius: 8px;

    &Active {
      background: rgba(0, 91, 209, 0.12);
      border: 1px dashed #9bc6ff;
    }

    &Text {
      display: flex;
      align-items: center;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    button {
      color: $primaryText;
      cursor: not-allowed;
    }
  }
}

.dropzoneBackdrop {
  z-index: 1400;
  background: rgba(0, 91, 209, 0.8);
  padding: 20px;

  &Content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 2px dashed #ffffff;
    border-radius: 16px;
    text-align: center;
    color: #ffffff;
    font-size: 32px;
    line-height: 44px;
    font-weight: 500;
    white-space: pre-line;
  }
}
