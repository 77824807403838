$scrollBorderRadius: 4px;
$scrollWidth: 6px;
$scrollBackgroundColor: #dedede;

%customScroll {
  &::-webkit-scrollbar {
    width: $scrollWidth;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $scrollBorderRadius;
    background-color: $scrollBackgroundColor;
  }
}

:export {
  scrollWidth: $scrollWidth;
  scrollBorderRadius: $scrollBorderRadius;
  scrollBackgroundColor: $scrollBackgroundColor;
}
