.main {
  &Checkbox {
    &EmailWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      span {
        font-size: 16px;
      }

      button {
        margin-left: 12px;
      }
    }

    &Wrapper {
      display: flex;
      align-items: center;

      margin-bottom: 24px;
      padding: 18px 0;

      border-top: 1px solid #dedede;
      border-bottom: 1px solid #dedede;

      span {
        font-size: 16px;
      }

      label span {
        padding: 0 7px 0 0;
      }
    }
  }

  &Button {
    margin-bottom: 16px;
  }
}

.actionBtn {
  position: absolute;
  right: 8px;
  top: 15px;
  width: 30px;
}

.bottomMessage {
  min-height: 70px;
}

.closeBtn {
  width: 30px;

  position: absolute;
  right: 5px;
  top: 15px;
}

.whiteSpace {
  white-space: normal;
}