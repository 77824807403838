@font-face {
    font-family: 'Grtsk Peta';
    src: url('GrtskPeta-Regular.eot');
    src: local('Grtsk Peta Regular'), local('GrtskPeta-Regular'),
        url('GrtskPeta-Regular.eot?#iefix') format('embedded-opentype'),
        url('GrtskPeta-Regular.woff2') format('woff2'),
        url('GrtskPeta-Regular.woff') format('woff'),
        url('GrtskPeta-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Grtsk Peta';
    src: url('GrtskPeta-Medium.eot');
    src: local('Grtsk Peta Medium'), local('GrtskPeta-Medium'),
        url('GrtskPeta-Medium.eot?#iefix') format('embedded-opentype'),
        url('GrtskPeta-Medium.woff2') format('woff2'),
        url('GrtskPeta-Medium.woff') format('woff'),
        url('GrtskPeta-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
} 

@font-face {
    font-family: 'Grtsk Peta';
    src: url('GrtskPeta-Semibold.eot');
    src: local('Grtsk Peta Semibold'), local('GrtskPeta-Semibold'),
        url('GrtskPeta-Semibold.eot?#iefix') format('embedded-opentype'),
        url('GrtskPeta-Semibold.woff2') format('woff2'),
        url('GrtskPeta-Semibold.woff') format('woff'),
        url('GrtskPeta-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Grtsk Peta';
    src: url('GrtskPeta-Bold.eot');
    src: local('Grtsk Peta Bold'), local('GrtskPeta-Bold'),
        url('GrtskPeta-Bold.eot?#iefix') format('embedded-opentype'),
        url('GrtskPeta-Bold.woff2') format('woff2'),
        url('GrtskPeta-Bold.woff') format('woff'),
        url('GrtskPeta-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}