@import 'src/theme/scss/vars.module';

.variantContainer {
  max-height: 250px;
  overflow-y: auto;
  display: block;
  @extend %customScroll;
}

.fullHeightVariantContainer {
  max-height: 100%;
  height: 100%;
}

.itemVariant {
  margin-bottom: 3px;
}
