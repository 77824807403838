@import 'src/theme/scss/vars.module';

.counter {
  min-width: 20px;
  height: 20px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  padding: 4px;

  border-radius: 10px;
  background-color: rgba($primaryDark, 0.28);
  color: #fff;
  line-height: 1;
}
