.formInput {
  margin-bottom: 0;
}

.content {
  margin-bottom: 20px;
}

.row {
  border-bottom: 1px dashed #dedede;
  margin-bottom: 8px;

  &.thead {
    border-bottom: 1px solid #dedede;

    .item {
      font-size: 12px;
      color: #7a8694;
    }
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
}

.item {
  padding: 8px 0;
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.42;

  &.hasWidth {
    flex-grow: 0;
  }
}

.accordion {
  box-shadow: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 26px;

  &.notIsEditMode {
    margin-bottom: 20px;
  }
}

.radioLabel {
  padding-left: 8px;
}
