.wrapper {
  padding: 8px 0;

  svg {
    flex-shrink: 0;
  }
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}