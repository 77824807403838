.wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 55%;
}

.buttonWrapper {
  margin: 0 10px;
}

.iconWrapper {
  margin-right: 10px;
}

.infoIcon {
  width: 16px;
  height: 16px;
  & path {
    stroke: #E24343
  }
}

.warn {
  background-color: #FFE6E6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 16px;
  width: 370px;
  padding: 0 16px;
  height: 32px;
}