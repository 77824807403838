.userBlock {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 8px;

  .avatar {
    width: 48px;
    height: 48px;
    background-color: #f3f6fb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7a8694;
    font-weight: 500;
  }
}
