.wrapperFiltersPopup {
  & > div {
    padding: 12px;
  }
}
.form {
  & > div {
    margin-bottom: 7px;
  }
  & button {
    padding: 5px 10px;
    margin-top: 7px;
  }
}
