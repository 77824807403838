@import 'src/theme/scss/vars.module';

.wrapper {
  background-color: #f3f6fb;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #dedede;

  .barContent {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
  }

  & button {
    color: $primary;
  }

  &.pending {
    background-color: $warningLight;
    border-color: #f8c194;

    & button {
      color: $warningText;
      svg {
        color: $warningText !important;
      }
    }
  }

  &.rejected {
    background-color: $dangerLight;
    border-color: #ff9393;

    & button,
    .barContent svg {
      color: $dangerDark !important;
    }
  }

  &.approved {
    border-color: #79ca7d;
    background-color: $successLight;

    & button {
      color: $textGreen;
      svg {
        color: $textGreen !important;
      }
    }
  }
}

.icon {
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}
