@import 'src/theme/scss/vars.module';

@mixin inactiveTab {
  font-weight: 400 !important;
  color: $primaryText !important;

  &:active {
    background: none !important;
    color: $primaryText !important;
  }
}

.whiteTabs {
  border-radius: 8px !important;
  background: #fff;

  button:not(.whiteActiveTab, .grayActiveTab) {
    @include inactiveTab;
  }
}

.grayTabs {
  border-radius: 8px !important;
  background: $primaryLight;

  button:not(.whiteActiveTab, .grayActiveTab) {
    @include inactiveTab;
  }
}

.whiteActiveTab {
  border-radius: 8px !important;
  background: $primaryLight !important;
}

.grayActiveTab {
  border-radius: 8px !important;
  background: #fff !important;
}
