@import 'src/theme/scss/vars.module';

.container {
  display: flex;
  gap: 24px;
}

.containerRight {
  display: flex;
  gap: 24px;
  flex-direction: column;

  @media (max-width: $layoutBreakpointXxl) {
    display: none;
  }

  & .stepper {
    width: 400px;
    height: 300px;
  }

  & .communicationBlock {
    height: 98px;
  }
}
