@import 'src/theme/scss/vars.module';

.wrapper {
  height: 0;
  transform: translateY(-61px);
  position: fixed;
  z-index: 1200;
  bottom: 3%;
  right: 40px;
  margin-left: auto;
  user-select: none;

  &Content {
    height: 61px;
    position: relative;
    border: 1px solid rgba(0, 47, 108, 0.06);
    box-shadow: 0 4px 8px rgba(86, 106, 129, 0.16);
    background-color: #ffffff;
    border-radius: 6px;
    opacity: 0.8;

    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

.slider {
  background-color: rgba($primaryDark, 0.06);
  border-radius: 6px;
  height: 100%;
  position: relative;
  z-index: 2;
}

.mark {
  background-color: rgba($primaryDark, 0.06);
  height: calc(100% - 7px);
  width: 10px;
  position: absolute;
  top: 3px;
  z-index: 1;
  border-radius: 4px;
}
