@import 'src/theme/scss/vars.module';

.form {
  display: flex;
  flex-direction: column;

  &Loading {
    opacity: 0.5;
    pointer-events: none;
  }
}

.container {
  &Title {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}

.formItemView {
  display: flex;
  flex: 1;
  flex-direction: column;

  margin-bottom: 24px;

  &Helper {
    margin-bottom: 48px;
  }
}

.formItem {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 32px;

  &NoMargin {
    margin-bottom: 0;
  }

  &Control {
    display: flex;

    &Input {
      width: 100%;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }
  }
}

.tooltip {
  margin-left: 7px;
}

.flexLabel {
  display: flex;
  gap: 4px;
}

.errorLabel {
  color: $danger;
}

.followScroll {
  position: sticky;
  bottom: 0;
  padding-bottom: 10px;
  background-color: #fff;
}

.footerCover {
  &ExtraActions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &NoExtraActions {
    display: flex;
    justify-content: flex-end;
  }
}
