.loading {
  background-color: #f3f6fb;

  &:hover {
    background-color: #f3f6fb;
  }

  &:active {
    background-color: #f3f6fb;
  }
}
