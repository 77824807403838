.container {
  display: flex;
  align-items: baseline;
  gap: 16px;
}

.actionButton {
  cursor: pointer;

  svg {
    path {
      fill: currentColor !important;
    }
  }
}
