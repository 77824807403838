.closeBtn {
  width: 30px;

  position: absolute;
  right: 5px;
  top: 15px;
}

.mainButton {
  margin-top: 16px;
}
