.ellipsis {
  display: block!important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.subtagList {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 8px;
}
.tagsList {
  display: flex;
}

.subtagListTooltip {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.chip{
  background: rgba(0, 47, 108, 0.06);
  &Tooltip{
    border: 1px solid #DEDEDE;
    margin-bottom: 10px;
  }
}
