@import 'src/theme/scss/vars.module';

.wrapper {
  border: 1px solid #cacbcc;
  padding: 8px 16px;
  border-radius: 8px;
  max-width: 100%;

  &.blue {
    background-color: $primaryBlue;

    .title {
      color: $primaryDark;
      font-weight: 600;
    }

    .subtitle {
      color: $primary;
    }
  }

  .title {
    font-weight: 500;
  }

  .subtitle {
    color: $secondary;
  }
}

.noBorder {
  padding: 0;
  border-width: 0;
}

.content {
  overflow: hidden;
  max-width: 100%;
}

.body {
  max-width: 100%;
  overflow: hidden;
}
