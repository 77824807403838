.wrapper {
    display: flex;
    padding: 15px 10px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    color: #9FA8B3;
    font-size: 14px;
}