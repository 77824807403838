.formInput {
  margin-bottom: 0;
}

.content {
  margin-bottom: 20px;
}

.row {
  border-bottom: 1px dashed #dedede;
  margin-bottom: 8px;

  &.thead {
    border-bottom: 1px solid #dedede;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  max-width: 100%;
  min-width: 0;
}

.item {
  padding: 8px 0;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 1.42;

  &.hasWidth {
    flex-grow: 0;
  }

  min-width: 0;
}

.accordion {
  box-shadow: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 26px;

  &.notIsEditMode {
    margin-bottom: 20px;
  }
}

.radioLabel {
  padding-left: 8px;
}

.linearProgress {
  flex: 1;
}
