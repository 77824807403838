@import 'src/theme/scss/vars.module';

.wrapper {
  padding: 12px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: #f3f6fb;
  }
}

.avatarWrapper {
  position: relative;

  .avatarBadge {
    position: absolute;
    top: -8px;
    left: 24px;
  }
}

.titleWrapper {
  @include line-clamp;
}

.textWrapper {
  width: 486px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
