.whoisWrapperBase {
  width: 45px;
  height: 20px;
  padding: 0 4px;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
}

.whoisWrapper {
  &--valid {
    @extend .whoisWrapperBase;

    background: #13b81a;
  }

  &--invalid {
    @extend .whoisWrapperBase;

    background: #e11111;
  }
}

.acceptButton {
  background-color: #ffffff;
  color: #002f6c;

  &:hover {
    background-color: #0041a0;
    color: #ffffff;
  }
}

.declineButton {
  background-color: #e11111;
  color: #ffffff;
}
