@import 'src/theme/scss/vars.module';

.removeIcon {
  path {
    stroke: $secondaryText;
  }

  &:hover,
  &:focus,
  &:active {
    path {
      stroke: $secondaryDark;
    }
  }
}

.inputWrapper {
  padding: 0 35px 0 16px !important;
}

.input {
  padding-left: 0 !important;
}

.fileIcon {
  background-color: rgba(0, 47, 108, 0.04);
  width: 40px;
  height: 40px;
}
