@import 'src/theme/scss/vars.module';

.container {
  display: flex;
  align-items: center;
  margin: 0;
}

.containerTabs {
  list-style-type: none;
  display: flex;
  gap: 20px;
  padding: 0 20px;
  margin-bottom: 0;
  margin-top: 0;
  background-color: #ffffff;
  border-radius: 12px;
  height: 44px;
  flex: 1;

  @media (max-width: 1100px) {
    // gap: 0;
  }

  & .tab {
    & a {
      padding: 12px 0;
      display: inline-block;
      white-space: nowrap;
      border-bottom: 2px solid transparent;
      color: $primaryText;

      &[class~='active'] {
        font-weight: 500;
        border-color: #f00;
      }
    }
  }
}

.moreItem {
  padding: 10px 18px;
  display: block;
}
