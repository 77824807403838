@import 'src/theme/scss/vars.module';

.filtersContainer {
  flex-grow: 1;
  // overflow-x: clip;
  max-width: 100%;
}

.inputSearch {
  background: #f0f2f5;
  border-radius: 8px;
  height: 44px;
  border: none;
  padding-right: 14px !important;

  & > * {
    border: none;
  }

  & input {
    padding: 0 20px;
    font-size: 14px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: inherit;
      -webkit-box-shadow: 0 0 0 40rem #f0f2f5 inset;
    }
  }

  &.inputBorder {
    background: #ffffff;
    border: 1px solid #cacbcc;
  }
}

.searchBtn {
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.buttonsContainer {
  background: rgba($primaryDark, 0.06);
  border-radius: 8px;
  display: inline-block;
  padding: 4px;

  & .buttonsGroup {
    background-color: transparent;
    box-shadow: none;

    & button {
      background-color: transparent;
      color: $secondary;
      padding: 8px 16px;
      border-radius: 4px;

      &.active {
        background-color: #ffffff;
        color: $primaryText;
        font-weight: 500;
      }

      & span {
        margin-left: 5px;
      }
    }

    & button:not(:last-of-type) {
      border-color: transparent;
      border-radius: 4px;
    }
  }
}

.buttonGroup:last-child {
  margin-right: 0;
}

.chipWrapper {
  //background-color: #f0f2f5;
  display: flex;
  flex-grow: 1;
  align-items: center;
  position: relative;
  border-radius: 8px;
  gap: 8px;
  justify-content: space-between;
  //  overflow-x: clip;

  &.disable {
    color: rgba(159, 168, 179, 1);
    background-color: rgba(0, 47, 108, 0.04);
  }
}

.chipAndTabsWrapper {
  background-color: #f0f2f5;
  display: flex;
  flex-grow: 1;
  align-items: center;
  position: relative;
  border-radius: 8px;
  //  overflow-x: clip;

  &.disable {
    color: rgba(159, 168, 179, 1);
    background-color: rgba(0, 47, 108, 0.04);
  }
}

.chipContainer {
  display: flex;
  position: absolute;
  gap: 4px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  z-index: 2;

  .mainContainer {
    display: flex;
  }
}

.subContainer {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 8px;
  box-shadow: 0 4px 8px rgba(40, 48, 60, 0.04),
    0 2px 20px rgba(40, 48, 60, 0.08), 0 1px 3px rgba(40, 48, 60, 0.06);
  border-radius: 6px;
  gap: 4px;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #dedede;
  }
}

.countHidden {
  position: relative;

  &:hover {
    .subContainer {
      z-index: 100;
      opacity: 1;
    }
  }
}

.chip {
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 36px;
  margin-right: 0;
  background-color: $primaryLight;

  &:hover {
    background-color: #e3e8ef;
  }

  &:active {
    background-color: #e3e8ef;
  }

  :global(.MuiChip-avatar) {
    margin-left: 8px;
  }

  &Edit {
    span {
      padding-left: 8px;
      padding-right: 6px;
    }

    input {
      padding-left: 0 !important;
    }
  }

  &WhiteVariant {
    background-color: #fff;
  }

  & .chipSearch {
    &:global(.MuiOutlinedInput-root) {
      padding-left: 0 !important;
      padding-right: 8px !important;
      max-width: 120px !important;
      background: transparent !important;
    }

    & input {
      padding: 5px;
      font-size: 12px;
      margin: -3px 0px;
      height: fit-content !important;
    }

    & fieldset {
      border: none;
    }
  }

  & .arrow {
    display: inline-block;
    margin-left: 5px;

    svg {
      height: 11px;
    }

    path {
      stroke: $primaryText;
    }
  }

  & .searchTypeIcon {
    display: flex;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.desc {
  transform: rotate(180deg);
}

.popperMenu {
  padding: 15px;
  box-shadow: 0 4px 8px rgba(40, 48, 60, 0.04),
    0 2px 20px rgba(40, 48, 60, 0.08), 0 1px 3px rgba(40, 48, 60, 0.06);
  border-radius: 8px;
  min-width: 308px;
}

.itemSort {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: $primaryText;
  margin-bottom: 25px;
  transition: all ease 0.3s;

  &.active {
    color: #005bd1;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: $primaryText;

    svg path {
      stroke: $primaryDark;
    }
  }

  & .hidden {
    display: none;
  }
}

.wrapperFilters {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.modal {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #dedede;
  }
}

.clearBtn {
  &.hidden {
    visibility: hidden;
  }
}

.rightBlockWrapper {
  margin-right: auto;
  margin-left: 24px;
}

.tabFiltersWrapper {
  @media (max-width: $layoutBreakpointXxl) {
    display: none;
    background: red;
  }
}

.activeFiltersCountBadge {
  background-color: $primary;
  font-size: 10px;
  display: flex;
  position: absolute;
  top: -6px;
  right: -6px;
  height: 20px;
  width: 20px;
  border-radius: 999px;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.smallActiveFiltersCountBadge {
  background-color: $primary;
  font-size: 8px;
  display: flex;
  position: absolute;
  top: -3px;
  right: -3px;
  height: 12px;
  width: 12px;
  border-radius: 999px;
  align-items: center;
  justify-content: center;
  color: #fff;
}
