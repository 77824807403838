@import 'src/theme/scss/vars.module';

.mainWrapper {
  display: flex;
  margin-right: -12px;
  margin-left: -12px;
}

.column {
  flex: 0 0 33.33333%;
  width: 33.333333%;

  padding: 0 12px;
  display: flex;
  flex-direction: column;

  @media (max-width: $layoutBreakpointXxl) {
    flex: 0 0 50%;
    width: 50%;
  }
}

.twoItemsColumn {
  flex: 0 0 50%;
  width: 50%;
}

.createBlock {
  background: rgba(0, 47, 108, 0.06);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 500px;

  &:hover,
  &:focus {
    background: rgba(0, 47, 108, 0.06);
  }

  svg path {
    stroke: $primary;
  }
}

.pagination {
  font-weight: 400;
  font-size: 16px;
}

.arrowWrapper {
  .arrow {
    padding: 10px 12px;

    &:disabled {
      svg path {
        stroke: $secondaryLight;
      }
    }
  }

  .next {
    transform: rotate(180deg);
  }
}

.editTitle {
  margin-left: 8px;
}
