.container {
  display: flex;
  justify-content: space-between;
  gap: 32px;

  &Btn {
    display: flex;
    gap: 24px;
  }
}
