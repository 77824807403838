@import 'src/theme/scss/vars.module';

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 24px;

  & label {
    gap: 15px;
  }
}

.logistics {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cargoParameters {
  margin-bottom: 12px;
}

.containerParameters {
  & * {
    color: $secondary;
  }
}
