@import 'src/theme/scss/vars.module';

.wrapper {
  display: flex;
  align-items: center;
  gap: 3px;
}
.icon {
  width: 16px;
  height: 16px;
}
