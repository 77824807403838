@import 'src/theme/scss/vars.module';

.wrapper {
  margin-top: -20px;
  overflow: hidden;
}

.headerWrapper {
  padding-left: 24px;
  padding-right: 24px;
  min-height: 39px;

  // Нужно для нормального отображения счетчика фильтров
  padding-top: 3px;
  border-bottom: 1px dashed #dedede;
}

.scroll {
  @extend %customScroll;

  margin-left: 12px;
  padding-right: 6px;
  overflow-x: hidden;
}

.table {
  overflow: visible;

  [class^='table_wrapperTable'] {
    overflow: visible;
  }

  td {
    background-color: $primaryLight;
    border-radius: 8px !important;
  }

  th {
    span {
      font-weight: 500;
    }
  }

  thead {
    display: none;
  }

  .avatar {
    width: 40px;
    height: 40px;
    background-color: #f3f6fb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #292929;
    font-weight: 500;
    font-size: 13px;
    flex-shrink: 0;
  }
}

.phoneNumber {
  a {
    display: flex;
    height: min-content;
  }

  span {
    color: $secondaryText;
    font-size: 11px;
    line-height: 14px;
  }
}
