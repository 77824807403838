@import 'src/theme/scss/vars.module';

.phone {
  display: flex;

  @media (max-width: $layoutBreakpointSm) {
    flex-direction: column;
    gap: 24px;

    & .phoneCode {
      flex: 0;
      margin-left: 0;
    }

    & .additionalPhoneWrapper {
      width: 100%;
    }
  }

  &Value {
    flex: 1 1 auto;

    &Input {
      padding-left: 4px;
    }

    &MobileOnlyInput {
      padding-left: 16px;
    }
  }

  &Code {
    flex: 0 0 160px;
    margin-left: 12px;
  }
}

.textCode {
  white-space: nowrap;
}

.mr {
  margin-right: 10px;
}
