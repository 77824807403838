@import 'src/theme/scss/vars.module';

.wrapper {
  display: flex;
  height: 68px;
  padding: 12px 0;
  background-color: $primaryLight;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.menuListWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.menuItem {
  height: 44px;
  -webkit-tap-highlight-color: transparent;
}
