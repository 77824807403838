@import 'src/theme/scss/vars.module';

.group {
  & button.active {
    background-color: #ffffff;
    color: $primaryText;
    font-weight: 500;

    &:hover {
      color: $primaryText;
    }
  }
}
