@import 'src/theme/scss/vars.module';

.wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.redDot {
  background-color: $dangerDark;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  align-self: flex-start;
  flex-shrink: 0;
}

.counterWrapper {
  display: flex;
  height: 20px;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid #dedede;
  border-radius: 9999px;
}
