@import 'src/theme/scss/vars.module';

.wrapper {
  padding: 16px 40px;

  @media (max-width: $layoutBreakpointLg) {
    padding: 16px 20px;
  }

  .headerTitle {
    @media (max-width: $layoutBreakpointLg) {
      display: none;
    }
  }
}
