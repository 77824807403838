@import 'src/theme/scss/vars.module';

.paper {
  width: 100%;
  background: $primaryLight;

  @media (min-width: $layoutBreakpointLg) {
    width: 400px;
    border-radius: 16px 0 0 16px;
    background: #fff;
  }
}

.header {
  padding: 24px 28px 20px 28px;

  @media (max-width: $layoutBreakpointLg) {
    background: $primaryLight;
    padding: 14px 20px;
  }

  .titleWrapper {
    align-items: center;

    @media (max-width: $layoutBreakpointLg) {
      cursor: pointer;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 28px 0 28px;
  max-height: 100%;
  overflow: auto;
  background: #fff;
  @extend %customScroll;

  @media (max-width: $layoutBreakpointLg) {
    padding: 20px;
    border-radius: 12px 12px 0 0;
  }
}

.desktopCloseButton {
  @media (max-width: $layoutBreakpointLg) {
    display: none;
  }
}

.mobileCloseButton {
  display: none;

  @media (max-width: $layoutBreakpointLg) {
    display: flex;
  }
}
