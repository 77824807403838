.lineChart {
  --line-chart-color: #0041a0;
  --line-axis-color: #111;
  --line-axis-line-color: #dedede;
  --line-background-color: #fff;

  :global {
    .MuiChartsAxis-label {
      transform: translateX(-10px);
    }

    .MuiMarkElement-root,
    .MuiLineElement-root {
      stroke: var(--line-chart-color);
    }

    .MuiMarkElement-root {
      fill: var(--line-chart-color);
    }

    .MuiHighlightElement-root {
      fill: var(--line-background-color);
      stroke: var(--line-chart-color);
    }

    .MuiChartsAxis-tickContainer {
      fill: var(--line-axis-color);
    }

    .MuiChartsAxis-line,
    .MuiChartsAxis-tick,
    .MuiChartsAxisHighlight-root {
      stroke: var(--line-axis-line-color);
    }
  }
}
