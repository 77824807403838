@mixin blockQuote {
    color: #999;
    background: #fff;
    font-style: italic;
    border-left: 2px solid #999;
    padding-left: 10px;
}

.editorWrapper {
    background: #fff;
}

.toolbarGrid {
    background: rgba(0, 47, 108, 0.06);
    width: fit-content;
}

.editorContainer {
    width: 100%;
    border: 1px solid #CACBCC;
    padding: 1rem;

    &.error {
      border-color: red;
    }
}

.editorContainer>div>p {
    margin: 0;
}

.editorContainer>div>p>code {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: "Inconsolata", "Menlo", "Consolas", monospace;
    font-size: 16;
    padding: 2,
}

.editorContainer>div>blockquote {
    @include blockQuote
}

.editorContainer :global(.public-DraftEditor-content) {
    min-height: 140px;

    div>blockquote {
        @include blockQuote
    }
}

.blockQuote {
    color: #999;
    background: #fff;
    font-family: "Hoefler Text", Georgia, serif;
    font-style: italic;
    border-left: 2px solid #999;
    padding-left: 10px;
}

.codeBlock {
    font-family: fira-code, monospace;
    font-size: inherit;
    background: #ffeff0;
    font-style: italic;
    word-wrap: normal;
    word-wrap: break-word;
    box-decoration-break: slice;
    padding: 0.3rem 2rem;
    border-radius: 0.2rem;
}

.leftAlign {
    text-align: left;
}

.rightAlign {
    text-align: right;
}

.centerAlign {
    text-align: center;
}

.justifyAlign {
    text-align: justify;
}