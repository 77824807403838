.sticky {
  position: sticky;
  left: 0;
}

.stickySecond {
  position: sticky;
  left: 200px;
}

.stickyRight {
  position: sticky;
  right: 0;
}

.bgDark {
  background-color: #f4f5f7;
}

.bgLight {
  background-color: #ffffff;
}
