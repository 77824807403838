@import 'src/theme/scss/vars.module';

.select {
  background-color: rgba(0, 47, 108, 0.06);
  width: 132px;
  border-radius: 4px;
  top: -1px;
  padding: 0 !important;
  height: 44px;

  @media (max-width: $layoutBreakpointMd) {
    top: auto;
  }

  svg {
    height: 16px !important;
    width: 16px !important;
  }
}

.select > fieldset {
  border: none;
}

.selectButton {
  padding: 12px 14px !important;
  height: auto !important;

  @media (max-width: $layoutBreakpointMd) {
    padding: 5px 10px 6px !important;
  }
}
