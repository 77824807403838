@import 'src/theme/scss/vars.module';

.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: initial;
}

.content {
  @media (max-width: $layoutBreakpointSm) {
    font-size: 12px;
  }
}

.list {
  margin-top: 0;
  padding-left: 16px;
}
