@import 'src/theme/scss/vars.module';

.wrapper {
  padding: 25px;
  box-shadow: 2px 2px 16px -7px #55555573;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ffffff;
  max-height: 700px;
  overflow: auto;

  @extend %customScroll;
}

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
