.watchedStatusWrapper {
  display: flex;
  align-items: center;

  p {
    margin: 0px 0px 0px 8px;
  }
}

.greenPoint {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #13B81A;
}

.redPoint {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FF0000;
}