.statusWrapperBase {
  width: fit-content;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  padding: 14px 16px;
  gap: 12px;
  white-space: nowrap;
}

.statusWrapper {

  &WARNING {
    @extend .statusWrapperBase;
    background: #ffe6e6;

    svg {

      path {
        stroke: #e24343;
      }
    }
  }

  &OPEN {
    @extend .statusWrapperBase;
    background: #cde3ff;
  }
}
