.formInput {
  margin-bottom: 0;
}

.content {
  margin-bottom: 1.5rem;
}

.row,
.line {
  border-bottom: 1px dashed #dedede;
  margin-bottom: 8px;
  max-width: 100%;

  &.thead {
    border-bottom: 1px solid #dedede;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &.inner {
    border-bottom: none;
  }
}

.row {
  min-width: 0;
}

.line {
  margin-bottom: 0;
  padding-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.item {
  padding: 8px 0;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 1.42;
  max-width: 50%;
  min-width: 0;

  &WithoutMaxWidth {
    max-width: unset;
  }

  &WithBigWidth {
    max-width: 759px;
  }

  &.hasWidth {
    flex-grow: 0;
  }

  &.inner {
    padding: 0;
  }
}

.accordion {
  box-shadow: none;
}

.header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 1rem;

  &.notIsEditMode {
    margin-bottom: 20px;
  }
}

.radioLabel {
  padding-left: 8px;
}

.linearProgress {
  margin-bottom: 4px;
  flex: 1;
}
