@import 'src/theme/scss/vars.module';

.title {
  height: 64px;
  margin-bottom: 15px;
}

.container {
  display: flex;
  gap: 24px;

  & .item {
    max-width: calc(33.33333% - 12px);
    flex: 0 0 33.33333%;
    height: 584px;

    @media (max-width: $layoutBreakpointXxl) {
      max-width: calc(50% - 12px);
      flex: 0 0 50%;
    }
  }
}
