@import 'src/theme/scss/vars.module';

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 17px 28px 19px;
  border-bottom: 1px solid #dedede;

  @media (max-width: $layoutBreakpointMd) {
    display: none;
  }
}

.content {
  padding: 24px 28px;

  @media (max-width: $layoutBreakpointMd) {
    padding: 0;
  }
}
